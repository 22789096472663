.editButton {
    border: 1px solid #000000;
    border-radius: 38px;
    text-transform: none;
    color: #000000;
    width: 55px;
    height: 32px;
    font-weight: 400;

    &:hover {
        color: #374957;
    }
}

.entityBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.typeImage {
    display: flex;
    gap: 4px;
    margin-top: -8px;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 24px;
    background-color: rgba(94, 74, 253, 0.05);
    border-radius: 4px;
    border: 1px solid rgba(94, 74, 253, 0.1);
}

.input {
    border-radius: 10px;
}

.btnWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 268px;
    border-top: 1px solid #efeef1;
    align-items: center;
    background-color: #ffffff;
    z-index: 2;
}

.saveButton {
    border-radius: 38px;
    text-transform: none;
    background-color: #000000;
    color: #fff;
    font-weight: 400;

    &:hover {
        background-color: #282727;
    }
}

.cancelButton {
    border-radius: 38px;
    text-transform: none;
    background-color: #edeef8;
    color: #000000;
    font-weight: 400;
}

.removeButton {
    display: flex;
    flex-direction: row;
    gap: 5px;
    border: none;
    border-radius: 38px;
    text-transform: none;
    color: #000000;
    background-color: #fff;

    &:hover {
        border: none;
        background-color: #fff;
    }
}
