.workflowCard {
    &Container {
        position: relative;
        margin-top: 16px;
        max-width: 210px;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid var(--border, #efeef1);
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(7, 9, 31, 0.08);
    }
    &Name {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &Parameters {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        &RiskLevel {
            font-size: 20px;
            font-weight: 600;
        }
        &Findings {
            width: 80px;
            height: 25px;
            padding: 4px 5px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #6b869a;
            background-color: #edeef8;
            border: 1px solid #5e4afd1a;
        }
    }
    &Link {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &Icon {
        position: absolute;
        top: 38px;
        right: -40px;
    }
    &Loading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        max-width: 210px;
        height: 86px;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid var(--border, #efeef1);
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(7, 9, 31, 0.08);
    }
}
