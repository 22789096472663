.discoveryRow {
    &Cell {
        padding: 8px 24px 8px 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
    &Link {
        color: #5e4afd;
        text-decoration: none;
        font-weight: 600;
    }
    &RiskImg {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
