.apiResponse {
    &Attributes {
        &Wrapper {
            background-color: #fff;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            max-height: 300px;
            overflow: auto;
        }
        &Name {
            font-weight: 500;
            cursor: pointer;
            border-radius: 5px;
            &.hoverEffect:hover {
                background-color: #d1ccfd;
            }
        }
        &Description {
            color: #666;
        }
    }
}

$risk-padding: 2px 4px;
$white-color: #fff;

.riskNone {
    background-color: #9aa3ab;
    padding: $risk-padding;
    color: $white-color;
}
.riskLow {
    background-color: #4fbb7c;
    padding: $risk-padding;
    color: $white-color;
}
.riskMedium {
    background-color: #ffae4f;
    padding: $risk-padding;
    color: $white-color;
}
.riskHigh {
    background-color: #b91f2a;
    padding: $risk-padding;
    color: $white-color;
}
.riskFraudulent {
    background-color: #8a121b;
    padding: $risk-padding;
    color: $white-color;
}
