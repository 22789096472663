.repoMainUserPlaybooksList {
    &Title {
        display: flex;
        justify-content: center;
        color: #262626;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
    }
    &Table {
        &Head {
            background-color: #262626;
            &Row {
                th {
                    color: #fff;
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }
        &Body {
            &Link {
                text-decoration: none;
                color: #7b61ff;
                font-size: 16px;
                font-weight: 600;
            }
            &Category {
                font-size: 16px;
                font-weight: 600;
                line-height: 130%;
            }
        }
    }
    &Input {
        border-radius: 10px;
    }
}
