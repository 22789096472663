.dialogWrapper {
    height: 195px;
    width: 430px;
    border-radius: 16px;
}

.cancelButton,
.searchButton,
.similarToolButton {
    border-radius: 38px;
    text-transform: none;
    font-weight: 400;
    height: 32px;
    font-size: 14px;
}

.cancelButton,
.searchButton {
    background-color: #edeef8;
    color: #000000;
}

.similarToolButton {
    background-color: #000000;
    color: #fff;
    &:hover {
        background-color: #282727;
    }
    &:disabled {
        color: #aeaeae;
    }
}
