.findingsItem {
    &Wrapper {
        position: relative;
        width: 100%;
        padding: 12px;
        background-color: #f9fafb;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid #efeef1;
    }
    &Title {
        &Wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 100%;
        }
        &Text {
            &Name {
                color: #000;
                font-size: 13px;
                font-weight: 300;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &Value {
                color: #6b869a;
                font-size: 13px;
                font-weight: 600;
                max-width: 100px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &Icon {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &Wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }
        }
    }
    &Main {
        width: 100%;
        &Wrapper {
            margin-top: 10px;
        }
        &Name {
            font-size: 14px;
            font-weight: 500;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &Wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &Value {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            word-wrap: break-word;
        }
    }
    &Description {
        &Wrapper {
            margin-top: 10px;
            margin-bottom: 5px;
        }
        &Text {
            width: 100%;
            font-weight: 300;
            word-wrap: break-word;
        }
    }
    &RiskScore {
        margin-top: 13px;
        &Title {
            color: #9aa3ab;
            font-size: 14px;
            font-weight: 500;
        }
        &Icons {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
        &Buttons {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 5px;
            &Cancel {
                color: #9aa3ab;
                font-size: 14px;
                border-radius: 38px;
                text-transform: none;
                height: 24px;
                width: 80px;
            }
        }
    }
    &RiskLevel {
        &Icon {
            margin-top: 10px;
        }
    }
}

.input {
    border-radius: 10px;
}
