.riskLevel {
    &Container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 170px;
        position: relative;
    }
    &Checkbox {
        border-radius: 20;
    }
    &Menu {
        &Text {
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    &DescriptionWrapper {
        position: absolute;
        top: 40px;
        left: -80px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid #efeef1;
        border-radius: 10px;
        width: 250px;
        min-height: 230px;
        background-color: #fff;
        z-index: 3;
    }
    &BtnWrapper {
        display: flex;
        padding: 10px 10px 0 10px;
        justify-content: space-between;
        border-top: 1px solid #efeef1;
        align-items: center;
    }
}
.input {
    border-radius: 10px;
    background-color: #f8f8f8;
}
.saveButton {
    border-radius: 38px;
    text-transform: none;
    background-color: #000000;
    color: #fff;
    font-weight: 400;
    width: 77px;
    &:hover {
        background-color: #282727;
    }
}

.cancelButton {
    border-radius: 38px;
    width: 77px;
    text-transform: none;
    background-color: #edeef8;
    color: #000000;
    font-weight: 400;
}

.label {
    font-size: 14px;
    line-height: 150%;
}
