.toolMenuToolbox {
    &Container {
        max-width: 30vw;
        min-width: 24vw;
        padding: 20px 24px;
        overflow: auto;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #efeef1;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(7, 9, 31, 0.08);
    }
    &Tool {
        &Category {
            position: relative;
            font-size: 14px;
            order: 2;
            &Wrapper {
                display: flex;
                flex-direction: column;
                gap: 15px;
                background-color: #f7f8fd;
                border-radius: 10px;
                padding: 8px 16px;
            }
            &Number {
                order: 3;
                align-self: flex-end;
                color: #374957;
                font-size: 12px;
            }
        }
    }
}
