.metricsCard {
    &Container {
        display: flex;
        justify-content: center;
        width: 208px;
        height: 74px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #fff;
        align-items: center;
        padding: 20px 15px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        gap: 20px;
    }
    &MainValue {
        display: flex;
        color: #374957;
        font-size: 32px;
        font-weight: 600;
    }
    &Indicator {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #374957;
        font-size: 12px;
        font-weight: 600;
        &Value {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
        }
    }
}
