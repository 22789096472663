.repoMainUserPlaybooksListHeader {
    &Wrapper {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    &Tab {
        text-transform: none;
        color: #262626;
        font-size: 25px;
        font-weight: 700;
        line-height: 140%;
    }
    &AddNewButton {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        width: 120px;
        height: 38px;
        border-radius: 38px;
        background-color: #096BBB;
        text-transform: none;
        color: #fff;

        &:hover {
            background-color: #2679BC;
        }
        @media (max-width: 374px) {
            gap: 0px;
            font-size: 12px;
            width: 105px;
        }
    }
    &Options {
        z-index: 11;
        position: absolute;
        top: 50px;
        right: 0;
        width: 176px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 10px;
        border: 1px solid #efeef1;
        background-color: #fff;
        color: #000;
        box-shadow: 0px 4px 20px 0px rgba(30, 41, 59, 0.1);
        backdrop-filter: blur(40px);
        &Item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            height: 40px;
            padding: 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            &:hover {
                background-color: #efeef1;
            }
        }
        &Icon {
            &Empty {
                width: 24px;
                height: 24px;
                background-image: url("../../../../../../../../assets/icons/Repo/addNewEmptyPlaybook.svg");
                background-size: cover;
                background-position: center;
            }
            &Ai {
                width: 24px;
                height: 24px;
                background-image: url("../../../../../../../../assets/icons/Repo/addNewAiPlaybook.svg");
                background-size: cover;
                background-position: center;
            }
        }
    }
}
