.asideMenu {
    &Container {
        display: flex;
    }
    &Arrow {
        position: absolute;
        bottom: 75px;
        right: 0;
        cursor: pointer;
    }
    &List {
        &Wrapper {
            padding: 5px;
        }
        &Item {
            display: block;
            justify-content: center;
        }
        &Element {
            transition: background-color 0.5s linear;
            border-radius: 6px;
            padding: 5px 10px;
            min-height: 48;
            justify-content: center;
            margin-top: 5px;
            &:hover {
                background-color: #292222;
            }
        }
        &Icon {
            height: 50px;
            justify-content: center;
        }
    }
}
