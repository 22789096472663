.caseOverview {
    &Container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        margin-right: -64px;
    }
    &Title {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        letter-spacing: -0.24px;
        margin: 30px 0 38px 0;
        &RiskScore {
            display: flex;
            flex-direction: row;
            gap: 15px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.18px;
            &Number {
                display: flex;
                justify-content: center;
                color: #fff;
                width: 29px;
                height: 25px;
                padding: 0px 4px;
                border-radius: 4px;
            }
        }
    }
}
