.repoFooter {
    &Container {
        margin-top: auto;
        width: 100%;
        background-color: #262626;
        color: #fff;
        height: 370px;
        padding: 0 200px;
        background-color: #262626;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        @media (max-width: 1024px) {
            width: calc(100% + 30px);
            margin-left: -15px;
            padding: 0 10px;
        }
        &Main {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 50px;
            @media (max-width: 425px) {
                flex-direction: column;
                align-items: start;
                gap: 20px;
            }
        }
        p {
            padding: 0;
        }
    }
    &Logo {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-weight: 700;
        line-height: 160%;
        color: #096bbb;
        flex: 1;
        padding: 0 10px;
        @media (max-width: 425px) {
            font-size: 18px;
        }
        img {
            width: 24px;
            height: 24px;
            @media (max-width: 425px) {
                width: 30px;
                height: 30px;
            }
        }
    }
    &LinkedIn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 180%;
        margin: 0;
        flex: 1;
        padding: 0 10px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    &Contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        padding: 0 10px;
        width: 100%;
        @media (max-width: 425px) {
            margin-top: 20px;
        }

        &Text {
            margin: 0;
        }
        &Label {
            color: #fff;
        }
        &Input {
            color: #fff;
            border-bottom: 1px solid transparent;
            &::before {
                border-bottom: 1px solid #fff;
            }
            &:hover {
                border-bottom: 1px solid #fff;
            }
        }
        &Button {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            width: 120px;
            height: 38px;
            border-radius: 38px;
            background-color: #096bbb;
            text-transform: none;
            color: #fff;
            &:hover {
                background-color: #2679BC;
            }
            @media (max-width: 425px) {
                width: 100%;
            }
        }
    }
    &Info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: 600;
        line-height: 180%;
        &Links {
            display: flex;
            justify-content: center;
            gap: 30px;
            a {
                text-decoration: none;
                color: #fff;
            }
        }
        &Rights {
            display: flex;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.link {
    text-decoration: none;
    color: inherit;
}
