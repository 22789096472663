.recommendationItemWrapper {
    position: relative;
    background-color: #ffffff;
    padding: 20px 16px;
    height: 100%;
}

.number {
    width: 35px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(94, 74, 253, 0.05);
    border: 1px solid rgba(94, 74, 253, 0.1);
    border-radius: 4px;
    color: #374957;
}

.icons {
    position: absolute;
    right: 0;
    bottom: 0;
}

.standartIcon {
    cursor: pointer;
    width: 17px;
    height: 17px;
}

.input {
    border-radius: 10px;
}
