.topBar {
    &Container {
        margin: 0px;
        z-index: 1000;
        background-color: #fff;
        color: #2d2d2d;
        position: fixed;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    }
    &ToolBar {
        padding: 0;
    }
    &StepsStatus {
        width: 135px;
        height: 25px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background-color: #edeef8;
    }
    &LoaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20;
        width: 144px;
        height: 43px;
        position: relative;
        background-color: #000000;
        font-size: 16;
        border-radius: 20px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
        padding: 10px 0px;
        margin: 8px;
        &:focus {
            border-radius: 20;
            border-color: #80bdff;
        }
    }

    &Notebook {
        &LinkBack {
            color: #374957;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            width: 130px;
            &:hover {
                color: #587286;
            }
        }
        &ButtonsWrapper {
            display: flex;
            align-items: center;
            gap: 20px;
        }
        &RequestButton {
            border-radius: 10px;
            text-transform: none;
            background-color: #5e4afd;
            color: #fff;
            min-width: 150px;
            &:hover {
                background-color: #7363ef;
            }
        }
    }
}
