.uploadImage {
    &Accordion {
        position: relative;
        background-color: #ffffff;
        box-shadow: none;
        border: 1px solid #efeef1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0;
        &::before {
            display: none;
        }
        &Body {
            background-color: #ffffff;
            border-radius: 10px;
            position: relative;
        }
    }
    &ActionsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
    &Title {
        &Container {
            display: flex;
            width: 100%;
            gap: 8px;
        }
        &Wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &Text {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        &Logo {
            border-radius: 50%;
            overflow: hidden;
            width: 35px;
            height: 35px;
        }
    }
    &InputWrapper {
        position: relative;
        padding: 0 30px 30px 30px;
    }
    &InputImg {
        margin-bottom: 15px;
    }

    &Subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #374957;
    }
    &Divider {
        margin-bottom: 20px;
        width: 97%;
        margin-left: 15px;
    }
    &LoaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.input {
    border-radius: 10px;
}

.resultWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;
    max-height: 600px;
    overflow: auto;
}

.editIcons {
    position: absolute;
    right: 40px;
    bottom: 5px;
}

.standartIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.inputFile {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    span {
        position: relative;
        display: inline-block;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        font-size: 14px;
        vertical-align: middle;
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 4px;
        background-color: #000;
        line-height: 22px;
        height: 40px;
        padding: 10px 20px;
        box-sizing: border-box;
        border: none;
        margin: 0;
        transition: background-color 0.2s;
    }

    input[type="file"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
        display: block;
        width: 0;
        height: 0;
    }

    input[type="file"]:focus + span {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:hover span {
        background-color: #2b2828;
    }
    &:active span {
        background-color: #2b2828;
    }

    input[type="file"]:disabled + span {
        background-color: #eee;
    }
}
