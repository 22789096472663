.customPopOver {
    &Container {
        position: fixed;
        margin-top: 5px;
        background: #fff;
        border: 1px solid #efeef1;
        border-radius: 20px;
        padding: 5px 10px;
        z-index: 1000;
        pointer-events: none;
    }
}
