.repoHeader {
    &Container {
        position: fixed;
        padding: 0 200px;
        width: 100%;
        height: 64px;
        top: 0;
        left: 0;
        border-bottom: 1px solid #efeef1;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
        z-index: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &Toolbox {
            position: fixed;
            padding: 0 200px;
            width: 100%;
            height: 64px;
            top: 0;
            left: 0;
            border-bottom: 1px solid #efeef1;
            background: #fff;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
            z-index: 500;
            display: flex;
            justify-content: space-between;
        }
    }
    &Share {
        // height: 195px;
        // width: 430px;
        border-radius: 16px;
        z-index: 1000;
    }
    &Title {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: 700;
        line-height: 160%;
        color: #096bbb;
        &Redstrings {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            margin-top: 5px;
            gap: 5px;
        }
        &FfaImg {
            width: 24px;
            height: 24px;
            @media (max-width: 425px) {
                width: 50px;
                height: 50px;
            }
        }
        &FfaText {
            color: #096bbb;
            text-decoration: none;
            @media (max-width: 425px) {
                display: none;
            }
        }
        &RedstringsText {
            font-size: 9px;
            font-weight: 400;
            color: #000;
            line-height: 160%;
        }
    }
    &User {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        &Link {
            text-decoration: none;
            color: #000;
            transition: color ease-in-out 0.2s;
            &:hover {
                color: #2679BC;
            }
        }
        &Info {
            &Wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 25px;
                width: 366.39px;
                justify-content: flex-end;
            }
            &Middle{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 25px;
            }
        }
        &Text {
            font-size: 14px;
            color: #000;
        }
    }
}


@media (max-width: 768px) {
    .repoHeader {
        &Container {
            padding: 0 15px;
            height: 50px;
            &Title {
                font-size: 14px;
            }
        }
    }
}
