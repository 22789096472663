.summary {
    &Wrapper {
        background-color: #ffffff;
        width: 300px;
        padding: 20px 16px;
        height: 100%;
        border-right: 1px solid #efeef1;
    }
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &Input {
        & fieldset {
            border: none;
        }
        & textarea {
            font-weight: 300;
        }
    }
}

.generateButton {
    display: flex;
    gap: 5px;
    border-radius: 38px;
    text-transform: none;
    border: 1px solid #000000;
    background-color: #fff;
    color: #000000;
    font-weight: 400;
}

.pencilIcon {
    position: absolute;
    right: 0;
    bottom: 0;
}

.editIcons {
    position: absolute;
    right: 10px;
    bottom: -25px;
}

.standartIcon {
    cursor: pointer;
    width: 17px;
    height: 17px;
}

.input {
    border-radius: 10px;
}

.rotating {
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
