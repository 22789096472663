
.templateInfo {
    &Title, &Description {
        // Common styles for both Title and Description can go here
    }
    
    // Mobile styles
    @media (max-width: 767px) {
        &Title {
            // Mobile-specific styles for Title
            font-size: 16px;
            margin-bottom: 10px;
        }
        &Description {
            // Mobile-specific styles for Description with added min-height for 2 rows
            font-size: 14px;
            margin-bottom: 8px;
        }
    }
    
    // Desktop styles
    @media (min-width: 768px) {
        &Title {
            // Desktop-specific styles for Title
            font-size: 24px;
            margin-bottom: 15px;
        }
        &Description {
            // Desktop-specific styles for Description
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}

// Preserving existing styles from the original file
.templateInfo {
    &Container {
        padding: 36px 36px 50px 36px;
        width: 50%;
        &Mobile {
            padding: 15px;
            width: 100%;
        }
    }
    &Title {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        &Feedback {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 5px;
            &Like {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 20px;
                transition: background-color 0.3s, border 0.3s, color 0.3s;
                color: #000;
                &:hover {
                    border: 1px solid #5e4afd80;
                }
                &Active {
                    color: #fff;
                    background-color: #5e4afd;
                    border: 1px solid #5e4afd;
                    &:hover {
                        background-color: #796aea;
                    }
                    border-radius: 20px;
                }
            }
            &Disliked {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 20px;
                transition: background-color 0.3s, border 0.3s, color 0.3s;
                &:hover {
                    border: 1px solid #5e4afd80;
                }
                &Active {
                    background-color: #5e4afd;
                    border: 1px solid #5e4afd;
                    &:hover {
                        background-color: #796aea;
                    }
                    border-radius: 20px;
                }
            }
            &Share {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 20px;
                transition: background-color 0.3s, border 0.3s, color 0.3s;
                &:hover {
                    background-color: #5e4afd;
                    .shareIcon {
                        background-image: url("../../../../../../../../assets/icons/Repo/shareIconActive.svg");
                    }
                }
                &PopOver {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 500;
                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        &MobileButtonsWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 32px;
            font-weight: 600;
            gap: 20px;
            margin-top: 20px;
        }
        &Name {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 32px;
            font-weight: 600;
            gap: 20px;
            &Text {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                &:hover {
                    .nameEditIcon {
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 1024px) {
                flex-wrap: wrap;
                justify-content: flex-start;
                span {
                    flex: 1 0 100%;
                }
            }

            &Button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                width: 120px;
                height: 38px;
                border-radius: 38px;
                background-color: #5e4afd;
                text-transform: none;
                color: #fff;
                &:hover {
                    background-color: #796aea;
                }
                @media (max-width: 374px) {
                    gap: 0px;
                    font-size: 12px;
                    width: 85px;
                }
            }
            @media (max-width: 320px) {
                font-size: 27px;
            }
        }

        &Designed,
        &DesignedLink {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            color: #6b869a;
            font-size: 14px;
            @media (max-width: 1024px) {
                margin-top: 10px;
            }
            @media (max-width: 320px) {
                font-size: 13px;
            }
        }

        &DesignedLink {
            text-decoration: none;
            cursor: pointer;
            max-width: 400px;
        }

        &Description {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            line-height: 160%;
            margin-top: 12px;
            min-height: 25px;
            max-height: 6.5em;
            overflow: auto;
            text-overflow: ellipsis;
            position: relative;
            &:first-letter {
                text-transform: uppercase;
            }
            &:hover {
                .nameEditIconDescriprion {
                    opacity: 1;
                }
            }
        }
    }
    &Subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        margin-top: 3px;
    }
    &Form {
        width: 100%;
        height: 100%;
    }
    &TextInput {
        border: none;
    }
    &Button {
        &Community {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            border-radius: 38px;
            text-transform: none;
            border: 1px solid #5e4afd;
            color: #000;
            font-weight: 400;
            min-width: 200px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 12px;
                min-width: 130px;
            }
            &:hover {
                background-color: #5e4afd;
                color: #fff;
                .joinComunityIcon {
                    background-image: url("../../../../../../../../assets/icons/Repo/joinComunityIconActive.svg");
                }
            }
        }
        &Updates {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            border-radius: 38px;
            text-transform: none;
            border: 1px solid #5e4afd;
            color: #000;
            font-weight: 400;
            min-width: 200px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 12px;
                min-width: 130px;
            }
            &:hover {
                background-color: #5e4afd;
                color: #fff;
                .subscribeIcon {
                    background-image: url("../../../../../../../../assets/icons/Repo/subscribeIconActive.svg");
                }
            }
        }
        &Wrapper {
            position: absolute;
            bottom: -36px;
            display: flex;
            flex-direction: row;
            gap: 36px;
            @media (min-width: 768px) and (max-width: 1024px) {
                gap: 10px;
            }
        }
        &Save {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            border-radius: 38px;
            text-transform: none;
            background-color: #5e4afd;
            color: #fff;
            min-width: 200px;
            &:hover {
                background-color: #5e4afdcc;
            }
        }
        &Cancel {
            cursor: pointer;
            border-radius: 38px;
            border: 1px solid #5e4afd;
            text-transform: none;
            color: #000;
            min-width: 150px;
            &:hover {
                color: #fff;
                border-color: #5e4afd;
                background-color: #5e4afd;
            }
        }
    }
    &Input {
        border-radius: 10px;
        &Description {
            margin-top: 15px;
        }
        &Error {
            color: red;
            font-weight: 400;
            font-size: 15px;
            margin: 5px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .playbookMain {
        &Container {
            padding: 0;
        }
        &Info {
            gap: 15px;
            padding-top: 70px;
            margin-bottom: 30px;
            &Title {
                font-size: 24px;
            }
            &SubTitle {
                font-size: 14px;
            }
        }
    }
}

.shareIcon {
    width: 24px;
    height: 24px;
    background-image: url("../../../../../../../../assets/icons/Repo/shareIcon.svg");
    background-size: cover;
    background-position: center;
}

.joinComunityIcon {
    width: 16px;
    height: 16px;
    background-image: url("../../../../../../../../assets/icons/Repo/joinComunityIcon.svg");
    background-size: cover;
    background-position: center;
}

.subscribeIcon {
    width: 16px;
    height: 16px;
    background-image: url("../../../../../../../../assets/icons/Repo/subscribeIcon.svg");
    background-size: cover;
    background-position: center;
}

.nameEditIconDescriprion,
.nameEditIcon {
    opacity: 0;
    min-width: 18px;
    height: 18px;
    background-image: url("../../../../../../../../assets/icons/Repo/iconEdit.svg");
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-image: url("../../../../../../../../assets/icons/Repo/iconEditHover.svg");
    }
}

.link {
    text-decoration: none;
    color: inherit;
}

