.apiResponse {
    &Section {
        &Wrapper {
            position: relative;
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            min-height: 100px;
            max-height: 600px;
            overflow: auto;
        }
        &Name {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;
            font-weight: 500;
            &Image {
                width: 100px;
                height: 100px;
                border-radius: 100px;
            }
        }
        &Title {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;
            font-weight: 500;
            border-radius: 5px;
            cursor: pointer;
            &Image {
                width: 100px;
                height: 100px;
                border-radius: 100px;
            }
            &.hoverEffect:hover {
                background-color: #d1ccfd;
            }
        }
        &Link {
            display: flex;
            align-items: center;
            font-weight: 500;
            gap: 5px;
            a {
                font-size: 14px;
            }
        }
        &Column {
            column-gap: 20px;
            margin: 0;
            padding-left: 20px;
        }
        &List {
            &Item {
                font-size: 14px;
                color: #666;
                &Title {
                    margin-right: 5px;
                    font-size: 15px;
                    color: #000;
                    font-weight: 500;
                    border-radius: 3px;
                    cursor: pointer;
                    &.hoverEffect:hover {
                        background-color: #d1ccfd;
                    }
                }
            }
        }
        &Loader {
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
        &MoreInfo {
            &Title {
                margin: 5px 0;
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                padding: 2px 4px;
            }
        }
        &Marker {
            &Btns {
                position: absolute;
            }
        }
    }
}

$risk-padding: 2px 4px;
$white-color: #fff;

.riskNone {
    background-color: #9aa3ab;
    padding: $risk-padding;
    color: $white-color;
}
.riskLow {
    background-color: #4fbb7c;
    padding: $risk-padding;
    color: $white-color;
}
.riskMedium {
    background-color: #ffae4f;
    padding: $risk-padding;
    color: $white-color;
}
.riskHigh {
    background-color: #b91f2a;
    padding: $risk-padding;
    color: $white-color;
}
.riskFraudulent {
    background-color: #8a121b;
    padding: $risk-padding;
    color: $white-color;
}
