.workFlow {
    &Container {
        width: 100%;
        margin-right: -64px;
        margin-top: 36px;
    }
    &Title {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.16px;
        margin-bottom: 16px;
    }
    &NewToolBtn {
        display: flex;
        gap: 8px;
        width: 100%;
        height: 45px;
        margin-top: 12px;
        background: #edeef8;
        border: 2px dashed #9aa3ab;
        border-radius: 10px;
        color: #000000;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}
