.repoPlaybookComponent {
    &Wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 345px;
        height: 157px;
        border-radius: 8px;
        padding: 20px 40px;
        border-top: 1px solid #efeef1;
        border-right: 1px solid #efeef1;
        border-bottom: 1px solid #efeef1;
        background: #fff;
        box-shadow: 0px 2px 6px -2px rgba(16, 12, 41, 0.08);
        transition: all 0.3s ease;
        @media (max-width: 374px) {
            width: 270px;
            justify-content: space-between;
            padding: 20px;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 100%;
            border-radius: 7px 0px 0px 7px;
            background: linear-gradient(
                88deg,
                rgba(107, 134, 154, 0.25) 1.99%,
                rgba(255, 255, 255, 0.25) 97.98%
            );
        }
        &:hover {
            box-shadow: 0px 2px 6px -2px #6b869a;
        }
    }
    &Name {
        font-size: 20px;
        font-weight: 500;
        line-height: 120%;
        height: 48px;
        @media (max-width: 374px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-height: 30px;
        }

        a {
            color: #262626;
            text-decoration: none;
        }
    }
    &User {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: #262626;
        font-size: 14px;
        font-weight: 400;
        line-height: 180%;
        cursor: default;
    }
    &Footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 25px;
    }
    &CaseType {
        padding: 0 5px;
        text-align: center;
        border-radius: 20px;
        background: rgba(224, 43, 56, 0.25);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 190px;
        transition: all 0.3s linear;
        cursor: default;
    }
    &Like {
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 20px;
        transition: background-color 0.3s, border 0.3s, color 0.3s;
        color: #000;
        font-size: 10px;
        width: 34px;
        &:hover {
            border: 1px solid #5e4afd80;
        }
        &Active {
            color: #fff;
            background-color: #5e4afd;
            border: 1px solid #5e4afd;
            &:hover {
                background-color: #796aea;
            }
            border-radius: 20px;
        }
    }
}
