.toolsMenu {
    &Title {
        font-size: 16px;
        font-weight: 500;
    }
    &Tool {
        &Category {
            position: relative;
            font-size: 14px;
            order: 2;
            &Wrapper {
                display: flex;
                flex-direction: column;
                gap: 15px;
                background-color: #f7f8fd;
                border-radius: 10px;
                padding: 8px 16px;
            }
            &Number {
                order: 3;
                align-self: flex-end;
                color: #374957;
                font-size: 12px;
            }
        }
    }
    &ManualDocumentation {
        &Wrapper {
            display: flex;
            justify-content: center;
            border-radius: 4px;
            border: 1px solid #efeef1;
            box-shadow: 0px 1px 2px 0px rgba(7, 9, 31, 0.08);
            height: 35px;
            width: 100%;
            margin-top: 10px;
        }
        &Text {
            width: 100%;
            color: #5e4afd;
            font-size: 13px;
            text-transform: none;
            font-weight: 400;
        }
    }
}
