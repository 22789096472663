.categoryInfo {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #6b869a;
    font-weight: 400;
    font-size: 14px;
    &:hover {
        .nameEditIcon {
            opacity: 1;
        }
    }
    &Input {
        border-radius: 10px;
    }
}

.nameEditIcon {
    opacity: 0;
    min-width: 18px;
    height: 18px;
    background-image: url("../../../../../../../../../../assets/icons/Repo/iconEdit.svg");
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-image: url("../../../../../../../../../../assets/icons/Repo/iconEditHover.svg");
    }
}
