.repoMainBlog {
    &Main {
        &Wrapper {
            padding: 80px 200px 120px 200px;
            max-height: 700px;
            @media (max-width: 768px) {
                padding: 80px 30px 80px 30px;
            }
        }
        &Title {
            display: flex;
            justify-content: center;
            width: 100%;
            color: #262626;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 140%;
            text-transform: capitalize;
            @media (max-width: 768px) {
                font-size: 30px;
            }
        }
    }
}
