.playbooksList {
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 16px;
        gap: 100px;
        width: 100%;

        &Items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 12px;
        }
    }
}
