.editButton {
    border: 1px solid #000000;
    border-radius: 38px;
    text-transform: none;
    color: #000000;
    width: 55px;
    height: 32px;
    font-weight: 400;

    &:hover {
        color: #374957;
    }
}

.entityBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input {
    border-radius: 10px;
}

.btnWrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 268px;
    border-top: 1px solid #efeef1;
    align-items: center;
    background-color: #ffffff;
    z-index: 2;
}

.cancelButton {
    width: 75px;
    border-radius: 38px;
    text-transform: none;
    background-color: #edeef8;
    color: #000000;
    font-weight: 400;
}

.saveButton {
    width: 75px;
    border-radius: 38px;
    text-transform: none;
    background-color: #000000;
    color: #fff;
    font-weight: 400;

    &:hover {
        background-color: #282727;
    }
}
