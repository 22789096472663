.dialogWrapper {
    height: 195px;
    width: 430px;
    border-radius: 16px;
    z-index: 1000;
}

.cancelButton,
.removeButton {
    border-radius: 38px;
    text-transform: none;
    font-weight: 400;
    height: 32px;
}

.cancelButton {
    background-color: #edeef8;
    color: #000000;
}

.removeButton {
    background-color: #000000;
    color: #fff;
    &:hover {
        background-color: #282727;
    }
}
