@mixin wrapperStyles {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    width: 220px;
    height: 40px;
    border-radius: 28px;
    border: 1px solid #efeef1;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        border: 1px solid #5e4afd;
        transition: all 0.3s ease-in-out;
    }
}

.stepsCarouselCard {
    &Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        max-width: 250px;

        &:hover {
            .addImage {
                opacity: 0.5;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                z-index: 10;
                &:hover {
                    opacity: 1;
                }
            }

            .stepsCarouselCardWrapper,
            .stepsCarouselCardActive {
                border-color: #5e4afd;
            }
        }
    }

    &Wrapper {
        @include wrapperStyles;
    }
    &Active {
        @include wrapperStyles;
        border: 1px solid #6b869a;
    }
    &NumberRound {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #efeef1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #9aa3ab;
    }
    &Name {
        width: 90%;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &Loading {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.addImage {
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}
