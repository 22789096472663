.discoveryEmpty {
    &Container {
        width: 100%;
        height: 150px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #efeef1;
    }
    &Text {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &Title {
            font-size: 24px;
            letter-spacing: -0.24px;
        }
        &SubTitle {
            font-size: 16px;
            letter-spacing: -0.16px;
            color: #9aa3ab;
        }
    }
}
