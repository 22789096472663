.repoLoginModal {
    &Container {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        background: #fff;
        width: 30%;
        height: 30%;
        border-radius: 8px;
        border-color: none;
        color: #fff;

        @media (max-width: 1200px) {
            height: 45%;
        }

        @media (max-width: 768px) {
            width: 50%;
            height: 35%;
        }

        @media (max-width: 425px) {
            width: 70%;
            height: 45%;
        }

        @media (max-width: 375px) {
            width: 80%;
            height: 45%;
        }
    }
    &Text {
        color: #000;
    }
    &Continue {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        padding: 0 10px;
        width: 100%;
        @media (max-width: 425px) {
            margin-top: 20px;
        }

        &Text {
            margin: 0;
        }
        &Label {
            color: #fff;
        }
        &Input {
            color: #fff;
            border-bottom: 1px solid transparent;
            &::before {
                border-bottom: 1px solid #fff;
            }
            &:hover {
                border-bottom: 1px solid #fff;
            }
        }
        &Button {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            width: 120px;
            height: 38px;
            border-radius: 38px;
            background-color: #096bbb;
            text-transform: none;
            color: #fff;
            &:hover {
                background-color: #2679bc;
            }
            @media (max-width: 425px) {
                width: 100%;
            }
        }
    }
}
