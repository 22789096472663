.playbookModal {
    &Wrapper {
        width: 550px;
        padding: 50px 30px;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        gap: 10px;
        position: relative;
    }
    &ContentContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &CloseButton {
        position: absolute;
        top: 10px;
        right: 0px;
    }
    &SaveButton {
        text-transform: none;
        font-size: 16px;
        color: #fff;
        background-color: #5e4afd;
        border-radius: 10px;
        &:hover {
            background-color: #7363ef;
        }
    }
    &Input {
        border-radius: 10px;
    }
}
