.notebook {
    &Container {
        display: flex;
        width: 100%;
        position: fixed;
        height: 100%;
        background-color: #f7f8fd;
    }
    &Component {
        &Wrapper {
            margin: 0px 64px 0 0;
            max-width: 100%;
            width: 100%;
            flex: 1 1 0%;
            overflow: hidden auto;
        }
    }
    &MenuComponent {
        &Wrapper {
            height: calc(100% - 65px);
            transition: all 0.5s ease;
            background-color: #ffff;
        }
    }
}
