.mainPage {
    &Container {
        padding: 0 64px;
        margin-right: -64px;
        // background-color: #f6f7fc;
        padding-bottom: 5px;
    }
    &Header {
        &Wrapper {
            display: flex;
            height: 60px;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
        }
        &Button {
            display: flex;
            gap: 5px;
            align-items: center;
            width: 144px;
            height: 40px;
            text-transform: none;
            border-radius: 38px;
            color: #fff;
            background-color: #000;
            &:hover {
                background-color: #343434;
                color: #fff;
            }
        }
    }
    &Loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 190px);
    }
    &Table {
        &Wrapper {
            display: flex;
            flex-direction: column;
            gap: 65px;
            background-color: #f6f7fc;
        }
    }
}
