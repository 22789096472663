.repoMainUserPlaybooksListCard {
    &Wrapper {
        position: relative;
        display: flex;
        height: 55px;
        padding: 16px;
        align-items: center;
        gap: 100px;
        border-radius: 10px;
        background: #fefefe;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
    &Content {
        display: flex;
        justify-content: space-between;
        align-items: "center";
        width: 100%;
        a {
            color: #7b61ff;
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;
            width: 60%;
        }
        span {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
    }
    &Dots {
        &Wrapper {
            display: flex;
            width: 25px;
            height: 25px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    &Options {
        z-index: 10;
        position: absolute;
        top: 35px;
        right: 20px;
        width: 176px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 10px;
        border: 1px solid #efeef1;
        background-color: #fff;
        color: #000;
        box-shadow: 0px 4px 20px 0px rgba(30, 41, 59, 0.1);
        backdrop-filter: blur(40px);
        &Item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            height: 40px;
            padding: 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            &:hover {
                background-color: #efeef1;
            }
        }
        &Icon {
            &Empty {
                width: 24px;
                height: 24px;
                background-image: url("../../../../../../../../assets/icons/Repo/deletePlaybook.svg");
                background-size: cover;
                background-position: center;
            }
        }
    }
}
