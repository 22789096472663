.newUserDialog {
    &InputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 15px;
    }
    &Input {
        border-radius: 10px;
    }
}

.dialogWrapper {
    width: 430px;
    border-radius: 16px;
}

.cancelButton,
.removeButton {
    border-radius: 38px;
    text-transform: none;
    font-weight: 400;
    height: 32px;
}

.cancelButton {
    background-color: #edeef8;
    color: #000000;
}

.removeButton {
    background-color: #000000;
    color: #fff;
    &:hover {
        background-color: #282727;
    }
}
