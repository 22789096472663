.myPlaybooks {
    &Container {
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        &Main {
            padding: 40px 200px 120px 200px;
            @media (max-width: 1024px) {
                padding: 0 100px;
            }
            @media (max-width: 768px) {
                padding: 0 50px;
            }
            @media (max-width: 425px) {
                padding: 0px;
            }
        }
    }
}
