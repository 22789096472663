.emptyResult {
    &Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
    }
    &Title {
        font-size: 24px;
        font-weight: 500;
    }
    &Subtitle {
        font-size: 16px;
        font-weight: 400;
    }
}
