.operations {
    &Radar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #efeef1;
    }
    &Bar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 700px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #efeef1;
    }
}
