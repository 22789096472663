.generateWithAiModal {
    &Container {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        background: #fff;
        width: 60%;
        height: 30%;
        border-radius: 8px;
        border-color: none;
        color: #fff;
    }
}
