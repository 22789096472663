.subscribe {
    &Dialog {
        &Container {
            padding: 10px;
            margin: 0;
            border-radius: 24px;
            overflow-y: auto;
            &Mobile {
                padding: 0;
                border-radius: 24px;
                overflow-y: auto;
            }
        }
        &Content {
            &Mobile {
                padding: 25px;
            }
        }
    }
    &Button {
        text-transform: none;
        border-radius: 10px;
        width: 50%;
        color: #fff;
        background-color: #5e4afd;
        &:hover {
            background-color: #7363ef;
        }
        &Wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
        }
        &Close {
            width: 50%;
            text-transform: none;
            border-radius: 10px;
            color: #fff;
            background-color: #000;
            &:hover {
                background-color: #302f2f;
            }
        }
    }
    &Input {
        border-radius: 10px;
    }
}
