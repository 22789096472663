.toolbox {
    &Container {
        padding: 0px 64px 100px 64px;
        height: 100vh;
        background-color: #f7f8fd;
    }
    &Header {
        display: flex;
        height: 60px;
        padding: 10px 20px;
    }
    &Main {
        &Wrapper {
            display: flex;
            flex-direction: row;
            gap: 20px;
            height: calc(100% - 64px);
        }
        &Loader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 69px;
            border-radius: 10px;
            border: 1px solid #efeef1;
            background: #fff;
        }
        &Select {
            width: 25%;
        }
        &Tool {
            min-width: 50vw;
            width: 100%;
            height: 100%;
            // overflow: scroll;
            // overflow: auto;
        }
    }
}
