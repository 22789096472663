.fraud {
    &Container {
        width: 100%;
        padding: 0 45px;
    }
    &Header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &Filters {
            font-size: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 50px;
        }
    }
    &Input {
        border-radius: 10px;
    }
    &MetricsWrapper {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
