.recommendation {
    &Wrapper {
        background-color: #ffffff;
        width: 300px;
        padding: 20px 16px;
        height: 100%;
        border-right: 1px solid #efeef1;
    }
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.addButton {
    display: flex;
    gap: 5px;
    border-radius: 38px;
    text-transform: none;
    border: 1px solid #000000;
    background-color: #fff;
    color: #000000;
    font-weight: 400;
}
