@mixin designationStyle {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;

    &Title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
    }

    &List {
        padding: 0 0 0 20px;
        margin: 0;
        font-size: 14px;
        color: #374957;
        font-weight: 400;
        line-height: 180%;
        &Item {
            display: flex;
            align-items: center;
            gap: 10px;
            &:hover {
                .templateDescriptionDesignationsMethodDeleteIcon {
                    opacity: 1;
                }
            }
        }
    }

    &AddNew {
        color: #374957;
        text-transform: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 180%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &DeleteIcon {
        opacity: 0;
        min-width: 14px;
        height: 14px;
        background-image: url("../../../../../../../../assets/icons/Repo/trash.svg");
        background-size: cover;
        background-position: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-image: url("../../../../../../../../assets/icons/Repo/trashHover.svg");
        }
    }
}

.templateDescription {
    &Container {
        display: flex;
        flex-grow: 1;
        width: 50%;
        background-color: #f4f5ff;
        padding: 36px;
        &Mobile {
            padding: 5px;
        }
    }
    &Main {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #efeef1;
        padding: 24px;
        overflow-y: auto;
        &Mobile {
            width: 100%;
            padding: 0px 15px 10px 15px;
        }
    }
    &Title {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 24px;
        &Name {
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            font-size: 18px;
            font-weight: 600;

            img {
                flex-shrink: 0;
                margin-right: 10px;
            }

            span {
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &Description {
            font-size: 14px;
            color: #374957;
            line-height: 180%;
            padding: 0 28px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &Designations {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 24px;
        border-top: 1px solid #efeef1;
        &Mobile {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-top: 15px;
            border-top: 1px solid #efeef1;
        }
        &Method {
            @include designationStyle;
        }
        &Autocomplete {
            &List {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 7px;
                cursor: pointer;
                color: #374957;
                font-size: 14px;
                font-weight: 400;
                line-height: 180%;
            }
        }
        &TextField {
            border-radius: 10px;
        }
        &RedFlag {
            @include designationStyle;
        }

        &Integrations {
            @include designationStyle;
        }
    }
}

.input {
    border-radius: 10px;
}
