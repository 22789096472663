.loading {
    &Dialog {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        &Contaier {
            width: 90%;
            height: 90%;
            max-width: 100%;
            margin: 0;
            border-radius: 24px;
        }
        &Title {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #efeef1;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            &Logo {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        &Content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            &Info {
                width: 50%;
                padding: 36px 36px 50px 36px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            &Description {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 30px;
                background-color: #f4f5ff;
                &Text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                &Title {
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0.24px;
                }
                &Subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 160%;
                }
                &Img {
                    max-width: 250px;
                    max-height: 190px;
                }
                &Progress {
                    width: 40%;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .loading {
        &Dialog {
            &Content {
                &Description {
                    width: 100%;
                    padding: 10px;
                    gap: 10px;
                    &Title {
                        font-size: 17px;
                        font-weight: 600;
                    }
                    &Subtitle {
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 160%;
                    }
                    &Progress {
                        width: 100%;
                    }
                }
            }
        }
    }
}
