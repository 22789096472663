.informativeSection {
    &Container {
        width: 230px;
        height: 170px;
        border-radius: 12px;
        border-top: 1px solid #efeef1;
        background: #fff;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &Title {
        font-weight: 500;
        font-size: 16px;
    }
    &Value {
        font-weight: 500;
        font-size: 36px;
    }
    &Limit {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 85px;
        color: #374957;
        font-size: 13px;
        border-radius: 4px;
        border: 1px solid rgba(94, 74, 253, 0.1);
        background: rgba(94, 74, 253, 0.05);
    }
}
