.step {
    &Container {
        height: 46px;
        border-radius: 8px;
        border: 1px solid #efeef1;
        box-shadow: 0px 2px 6px -2px rgba(16, 12, 41, 0.08);
        padding: 12px 18px;
        display: flex;
        align-items: center;
        position: relative;
        &Error {
            border: 1px solid red;
        }

        &Active {
            height: 46px;
            box-shadow: 0px 2px 6px -2px rgba(16, 12, 41, 0.08);
            padding: 12px 18px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 1px solid var(--main-purple, #5e4afd);
            background: #f7f8fd;
            position: relative;
        }
    }
    &Name {
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        &:hover {
            .nameEditIcon {
                opacity: 1;
            }
            .nameDeleteIcon {
                opacity: 1;
            }
        }
        &Mobile {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            font-weight: 500;
        }
        &Text {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &IconsWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }
    &Input {
        width: 100%;
        &::before {
            display: none;
        }
    }
}

.stepContainer,
.stepContainerActive {
    transition: all 0.3s ease;
    &:hover {
        .draggableIcon {
            opacity: 1;
        }
    }
}

.nameEditIcon {
    opacity: 0;
    min-width: 18px;
    height: 18px;
    background-image: url("../../../../../../../../../../assets/icons/Repo/iconEdit.svg");
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-image: url("../../../../../../../../../../assets/icons/Repo/iconEditHover.svg");
    }
}

.nameDeleteIcon {
    opacity: 0;
    min-width: 16px;
    height: 16px;
    background-image: url("../../../../../../../../../../assets/icons/Repo/trash.svg");
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-image: url("../../../../../../../../../../assets/icons/Repo/trashHover.svg");
    }
}

.draggableIcon {
    position: absolute;
    top: 50%;
    left: -19px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
