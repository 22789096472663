.repoMainPlaybooksList {
    &Container {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 120px 0px;
        @media (max-width: 768px) {
            padding: 0px 0px 120px 0px;
        }
    }
    &Title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        p {
            margin: 0;
        }
        &Sub {
            text-transform: uppercase;
            font-size: 18px;
            color: #7b61ff;
            @media (max-width: 425px) {
                font-size: 16px;
            }
        }
        &Main {
            font-size: 48px;
            font-weight: 700;
            line-height: 140%;
            @media (max-width: 425px) {
                font-size: 30px;
            }
            @media (max-width: 320px) {
                font-size: 28px;
            }
        }
    }
    &Main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        align-self: stretch;
        gap: 60px;
        max-height: 1000px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 10px;
        @media (max-width: 768px) {
            justify-content: center;
            height: 100%;
            max-height: 100%;
            overflow: auto;
        }

        @media (max-width: 425px) {
            gap: 30px;
        }
    }
}
