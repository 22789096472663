.steps {
    &Container {
        margin-bottom: 10px;
        margin-left: -15px;
        padding-left: 15px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        cursor: pointer;
        &Mobile {
            height: 100%;
            margin-bottom: 10px;
        }
    }
    &Title {
        margin: 12px 0;
        font-size: 18px;
        font-weight: 600;
    }
    &Main {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &Draggable {
            &Icon {
                position: absolute;
                left: -20px;
            }
        }
        &Mobile {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 0 3px 10px 3px;
        }
    }
    &Accordion {
        border-radius: 10px;
        cursor: default;
        &::before {
            display: none;
        }
    }
    &Mobile {
        &Button {
            border-radius: 10px;
            text-transform: none;
            background-color: #5e4afd;
            color: #fff;
            &:hover {
                background-color: #7363ef;
            }
            &Wrapper {
                display: flex;
                flex-direction: row;
                gap: 20px;
            }
        }
    }
}
