.addNewButton {
    &Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        width: 50%;
        height: 36px;
        font-size: 12px;
        font-weight: 500;
        background-color: #f4f5ff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease-out;
        &:hover {
            border: 2px dashed #9aa3ab;
            background-color: #e8e9ff;
        }
    }
    &Icon {
        width: 16px;
        height: 16px;
    }
}
