.newRunnerDialog {
    &InputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
    }
    &Input {
        border-radius: 10px;
    }
    &Share {
        &PopOver {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.dialogWrapper {
    width: 500px;
    border-radius: 16px;
}

.cancelButton,
.addButton {
    border-radius: 38px;
    text-transform: none;
    font-weight: 400;
    height: 32px;
}

.cancelButton {
    background-color: #edeef8;
    color: #000000;
}

.addButton {
    background-color: #000000;
    color: #fff;
    &:hover {
        background-color: #282727;
    }
}
