.buttonWithLogo {
    &Container {
        align-items: center;
        border-radius: 5px;
        margin: 0;
        cursor: pointer;
    }
    &Wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    &Btn {
        text-transform: none;
        justify-content: flex-start;
        width: 100%;
        background-color: #f7f8fd;

        :hover {
            opacity: 0.8;
            transition: opacity 0.3s linear;
        }
    }
    &Title {
        font-size: 14px;
    }
}
