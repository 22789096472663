.repo {
    &Container {
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        &Main {
            padding: 0 200px;
            @media (max-width: 1024px) {
                padding: 0 100px;
            }
            @media (max-width: 768px) {
                padding: 0 50px;
            }
            @media (max-width: 425px) {
                padding: 0px;
            }
            &Content {
                background-color: #f7f8fd;
                @media (max-width: 768px) {
                    margin: 0 -15px;
                }
                @media (max-width: 425px) {
                    margin: 0 -15px;
                }
            }
        }
    }
}
