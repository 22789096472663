.blogCard {
    &Link {
        text-decoration: none;
    }
    &Wrapper {
        display: flex;
        width: 350px;
        height: 455px;
        padding: 30px 30px 0px 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 10px;
        border: 1.5px solid #efeef1;
        background-color: #fff;
        @media (max-width: 768px) {
            width: 295px;
        }
        img {
            width: 290px;
            height: 130px;
            margin-bottom: 18px;
            @media (max-width: 768px) {
                width: 235px;
            }
        }
    }
    &Title {
        color: #262626;
        font-size: 25px;
        font-weight: 700;
        line-height: 120%;
        width: 100%;
        max-height: 60px;
        overflow: hidden;
    }
    &Text {
        color: #262626;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        max-height: 150px;
    }
}
