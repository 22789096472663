.step {
    &Container {
        width: 100%;
        transition: all 0.5s ease;
        margin-right: -64px;
        height: calc(100% - 65px);
        overflow-y: auto;
    }
    &Wrapper {
        width: 100%;
        // max-width: 70%;
        // margin-right: -64px;
        display: flex;
        flex-direction: column;
    }
    &AddNewTool {
        margin-top: 20px;
        width: 100%;
        height: 44px;
        background-color: #edeef8;
        border: 2px dashed #9aa3ab;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        :first-child {
            height: 100%;
            width: 100%;
        }
    }
    &Header {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &Link {
            text-decoration: none;
            font-weight: 400;
            font-size: 24px;
            letter-spacing: -0.01em;
            color: #6b869a;
        }
        &Btn {
            &Investigate {
                color: #5e4afd;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-transform: none;
            }
            &Completed {
                color: #4fbb7c;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-transform: none;
            }
        }
    }
    &Info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 20px;
        img {
            cursor: pointer;
        }
        &Title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            &Text {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
            }
        }
        &Icon {
            cursor: pointer;
            width: 17px;
            height: 17px;
        }
        &RiskLevel {
            display: flex;
            justify-content: center;
            width: 29px;
            height: 24px;
            border-radius: 4px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &Main {
        &Container {
            border-radius: 8px;
            background-color: #fff;
            margin-top: 20px;
        }
        &Wrapper {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
            padding: 0 20px 20px 20px;
            gap: 10px;
        }
        &ToolsWrapper {
            min-width: 520px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}

.input {
    border-radius: 10px;
    max-width: 300px;
}
