.findingsList {
    &Wrapper {
        height: calc(100% - 65px);
        width: 400px;
        overflow: auto;
        background-color: #ffff;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #efeef1;
        &Toolbox {
            height: 100%;
            max-width: 400px;
            min-width: 15vw;
            overflow: auto;
            background-color: #ffff;
            padding: 20px;
            border-radius: 8px;
            border: 1px solid #efeef1;
        }
    }
    &Title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &Img {
            cursor: pointer;
        }
        &Text {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &Items {
        &Wrapper {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}
