.entityElement {
    min-height: 48px;
    justify-content: center;
    border-radius: 4px;
    position: relative;

    &:hover {
        background-color: #edeef8;
        transition: all 0.3s linear;
    }
}

.newEntityElement {
    min-height: 48px;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    animation: background-animation 2s 3;

    &:hover {
        background-color: #edeef8;
        transition: all 0.3s linear;
    }
}

.arrowIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 5px;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.addEntityBtn {
    background-color: #edeef8;
    border: 2px dashed #ffffff;
    border-radius: 10px;
    width: 220px;
    height: 45px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
}

.newEntityBtnWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: fixed;
    bottom: 0;
    height: 70px;
    margin-left: -10px;
    width: 278px;
    border-top: 1px solid #efeef1;
    align-items: center;
    background-color: #ffffff;
    z-index: 2;
}

@keyframes background-animation {
    0%,
    100% {
        background-color: transparent;
    }
    50% {
        background-color: #edeef8;
    }
}
