.adminPanel {
    &Wrapper {
        padding: 0 64px;
        max-width: 1178px;
        margin: 0 auto;
    }
    &Title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &Text {
            font-size: 32px;
        }
        &Button {
            height: 40px;
            text-transform: none;
            font-size: 16px;
            border-radius: 10px;
        }
    }
    &List {
        &Wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: calc(100vh - 280px);
            overflow: auto;
            margin: 20px 0;
        }
    }
}

.input {
    border-radius: 10px;
}
