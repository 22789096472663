.CasesTable {
    &Container {
        // margin-top: 24px;
    }
    &Header {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0 20px;
        background-color: #eff1fc;
        height: 43px;
        border: 1px solid #efeef1;
        &Status {
            font-size: 14px;
            font-weight: 500;
        }
        &Quantity {
            font-size: 14px;
            font-weight: 500;
            color: #9aa3ab;
        }
    }
    &Main {
        &Container {
            overflow: hidden;
            max-height: 270px;
        }
        &TitleCell {
            padding: 20px 20px 8px 20px;
            color: #374957;
            border-bottom: 1px solid #efeef1;
            text-transform: uppercase;
        }
        &Cell {
            background-color: #fff;
            padding: 0 20px;
            height: 40px;
            border-bottom: 1px solid #efeef1;
            font-size: 14px;
        }
        &Urgency {
            &Loader {
                margin-left: 30px;
                display: flex;
                height: 100%;
                align-items: center;
            }
        }
    }
}

.text {
    &Underline {
        text-decoration: underline;
    }
}
