.eventElement {
    min-height: 48px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 16px;
    margin-left: 20px;

    &:hover {
        background-color: #edeef8;
        transition: all 0.3s linear;
    }
}

.newEventElement {
    min-height: 48px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 16px;
    margin-left: 20px;
    animation: background-animation 2s 3;

    &:hover {
        background-color: #edeef8;
        transition: all 0.3s linear;
    }
}

.date {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6b869a;
    font-size: 12px;
    font-weight: 400;
}

.arrowIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 5px;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.addEntityBtn {
    background-color: #edeef8;
    border: 2px dashed #ffffff;
    border-radius: 10px;
    width: 220px;
    height: 45px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
}

.newEntityBtnWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 278px;
    margin-left: -10px;
    border-top: 1px solid #efeef1;
    align-items: center;
    background-color: #ffffff;
    z-index: 2;
}

.roundIcon {
    position: absolute;
    left: -23px;
    top: 16px;

    &:hover {
        animation: scaleAnimation 3s infinite;
    }
}

.lineIcon {
    position: absolute;
    top: 42px;
    left: -14px;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes background-animation {
    0%,
    100% {
        background-color: transparent;
    }
    50% {
        background-color: #edeef8;
    }
}
