.attachments {
    &Element {
        cursor: default;
        min-height: 48px;
        border-radius: 4px;
        position: relative;
        gap: 8px;
        align-items: baseline;
        justify-content: space-between;
        &Description {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: 8px;
        }
        &Icons {
            z-index: 2;
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-self: center;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s linear;
            &Image {
                transition: all 0.3s linear;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        &:hover {
            background-color: #edeef8;
            transition: all 0.3s linear;
            .attachmentsElementIcons {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &Modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
