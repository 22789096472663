.apiResponse {
    &MainSection {
        &Wrapper {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 10px;
            min-height: 100px;
            max-height: 300px;
        }
        &Image {
            width: 100px;
            height: 100px;
            border-radius: 100px;
        }
        &Text {
            display: flex;
            flex-direction: column;
            &Bold {
                font-weight: 500;
            }
        }
    }
}
