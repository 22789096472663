.repoMainGenerator {
    &Container {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 60px 0px 120px 0px;
        width: 100%;
        @media (max-width: 1024px) {
            padding: 120px 0px 120px 0px;
        }
    }
    &Text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &Title {
            margin: 0;
            color: #262626;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 140%;
            @media (max-width: 425px) {
                font-size: 30px;
            }
            &Blue {
                color: #096bbb;
            }
        }
        &Description {
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 180%;
            @media (max-width: 425px) {
                text-align: center;
            }
        }
    }
}
