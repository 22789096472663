.discovery {
    &Container {
        width: 100%;
        margin-right: -64px;
    }
    &Title {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.16px;
        margin-bottom: 16px;
        &Cell {
            padding: 12px 24px 12px 24px;
            color: #6b869a;
            border-bottom: 1px solid #efeef1;
            text-transform: uppercase;
        }
    }
    &Table {
        &Wrapper {
            width: 100%;
            max-height: 400px;
            overflow-y: auto;
        }
        &Container {
            border-radius: 10px;
        }
    }
    &Loading {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
