.attachments {
    &Wrapper {
        background-color: #ffffff;
        width: 300px;
        padding: 20px 16px;
        height: 100%;
        border-right: 1px solid #efeef1;
    }
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &AddBtn {
        background-color: #edeef8;
        border: 2px dashed #ffffff;
        border-radius: 10px;
        width: 220px;
        height: 45px;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        &Wrapper {
            z-index: 3;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            position: fixed;
            bottom: 0;
            height: 70px;
            margin-left: -10px;
            width: 278px;
            border-top: 1px solid #efeef1;
            align-items: center;
            background-color: #ffffff;
        }
    }
    &InputFile {
        position: relative;
        display: inline-block;
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            outline: none;
            color: #000;
            text-decoration: none;
            font-size: 14px;
            width: 278px;
            height: 70px;
            border: 2px dashed #ffffff;
            border-radius: 10px;
            vertical-align: middle;
            text-align: center;
            line-height: 22px;
            height: 40px;
            padding: 10px 20px;
            box-sizing: border-box;
            border: none;
            margin: 0;
        }

        input[type="file"] {
            position: absolute;
            z-index: -1;
            opacity: 0;
            display: block;
            width: 0;
            height: 0;
        }
    }
}
