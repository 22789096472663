.standartApi {
    &Accordion {
        position: relative;
        background-color: #ffffff;
        box-shadow: none;
        border: 1px solid #efeef1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0;
        &::before {
            display: none;
        }
        &Body {
            background-color: #ffffff;
            border-radius: 10px;
            position: relative;
        }
    }
    &ActionsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
    &IframeResult {
        border-radius: 10px;
        padding: 16px;
        border: 1px solid #efeef1;
        background-color: #f7f8fd;
        position: relative;
        &MoreInfo {
            font-weight: 500;
            font-size: 16px;
        }
    }
    &Search {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0 30px;
        height: 56px;
        margin-bottom: 20px;
        &Button {
            border-radius: 38px;
            background-color: #000000;
            color: #fff;
            align-self: center;
            font-weight: 400;
            height: 40px;
            min-width: 100px;
            text-transform: none;
            &:hover {
                background-color: #282727;
            }
        }
    }
    &Title {
        &Container {
            display: flex;
            width: 100%;
            gap: 8px;
        }
        &Wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &Text {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        &Logo {
            border-radius: 50%;
            overflow: hidden;
            width: 35px;
            height: 35px;
        }
    }
    &Subtitle {
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #374957;
    }
    &Divider {
        margin-bottom: 20px;
        width: 97%;
        margin-left: 15px;
    }
    &LoaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // min-height: 200px;
    }
}

.input {
    border-radius: 10px;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resultWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;
    max-height: 62vh;
    overflow: auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #00000030; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #00000050; 
}

.comingSoon {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    padding: 0 30px 15px 45px;
}
