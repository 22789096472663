.apiRiskLevel {
    &Wrapper {
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 50px;
        height: 38px;
        border-radius: 8px;
        border: 1px solid #efeef1;
        cursor: pointer;
    }
    &Quantity {
        font-weight: 500;
        font-size: 14px;
        line-height: 8px;
    }
}
