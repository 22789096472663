.riskPopOver {
    &Wrapper {
        position: absolute;
        border-radius: 10px;
        border: 1px solid #efeef1;
        min-height: 330px;
        width: 320px;
        background-color: #fff;
        box-shadow: 0px 4px 20px 0px rgba(30, 41, 59, 0.1);
        backdrop-filter: blur(40px);
        padding: 16px;
    }
    &Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 30px;
        width: 100%;
        margin-bottom: 5px;
        &Name {
            max-width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #6b869a;
            font-size: 14px;
        }
        &Value {
            max-width: 200px;
            color: #000;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &Note {
        margin-top: 16px;
        &Title {
            color: #6b869a;
            font-size: 14px;
            font-weight: 500;
        }
        &Text {
            margin-top: 6px;
        }
    }
    &RiskScore {
        margin-top: 13px;
        &Title {
            color: #9aa3ab;
            font-size: 14px;
            font-weight: 500;
        }
        &Icons {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }
    &Buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 5px;
        &Cancel {
            color: #9aa3ab;
            font-size: 14px;
            border-radius: 38px;
            text-transform: none;
            height: 24px;
            width: 80px;
        }
        &Submit {
            border-radius: 38px;
            background-color: #000000;
            color: #fff;
            align-self: center;
            font-size: 14px;
            height: 24px;
            width: 80px;
            font-weight: 500;
            text-transform: none;
            &:hover {
                background-color: #282727;
            }
        }
    }
}

.input {
    border-radius: 10px;
}
