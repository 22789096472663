.routeCheck {
    &Accordion {
        position: relative;
        background-color: #ffffff;
        box-shadow: none;
        border: 1px solid #efeef1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0;
        &::before {
            display: none;
        }
        &Body {
            background-color: #ffffff;
            border-radius: 10px;
            position: relative;
        }
    }

    &Search {
        gap: 15px;
        padding: 0 30px;
        &Button {
            border-radius: 38px;
            background-color: #000000;
            color: #fff;
            align-self: center;
            font-weight: 400;
            height: 40px;
            min-width: 100px;
            text-transform: none;
            &:hover {
                background-color: #282727;
            }
        }
    }

    &ActionsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    &Title {
        &Container {
            display: flex;
            width: 100%;
            gap: 8px;
        }
        &Wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &Text {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        &Logo {
            border-radius: 50%;
            overflow: hidden;
            width: 35px;
            height: 35px;
        }
    }

    &IframeResult {
        border-radius: 10px;
        padding: 16px;
        border: 1px solid #efeef1;
        background-color: #f7f8fd;
        position: relative;
    }

    &InputWrapper {
        position: relative;
        padding: 0 30px 30px 30px;
    }

    &Subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #374957;
    }
    &Divider {
        margin-bottom: 20px;
        width: 97%;
        margin-left: 15px;
    }
    &LoaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.input {
    border-radius: 10px;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resultWrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    width: 100%;
    overflow: auto;
}

.editIcons {
    position: absolute;
    right: 40px;
    bottom: 5px;
}

.standartIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}
