.instructions {
    &Container {
        display: flex;
        width: 100%;
        height: 303px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        border: 1px solid #efeef1;
        background: #fff;
    }
    &Header {
        color: #3e3e3e;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
    }
    &Text {
        color: #3e3e3e;
        font-size: 18px;
        font-weight: 500;
    }
}
