.playbooksListEmpty {
    &Container {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 10px;
        background: #fefefe;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
}
