.dashboard {
    &Container {
        // margin-right: -64px;
        // padding-bottom: 65px;
        height: calc(90vh + 65px);
        background-color: #f7f8fd;
        padding: 0 74px;
        margin-right: -64px;
        padding-bottom: 5px;
        // max-width: 100%;
    }
    &Header {
        &Wrapper {
            display: flex;
            height: 60px;
            align-items: center;
            justify-content: space-between;
        }
        &Button {
            display: flex;
            gap: 5px;
            align-items: center;
            width: 144px;
            height: 40px;
            text-transform: none;
            border-radius: 38px;
            color: #fff;
            background-color: #000;
            &:hover {
                background-color: #343434;
                color: #fff;
            }
        }
    }
    &Tab {
        font-size: 18px;
        font-weight: 400;
        text-transform: none;
        &Wrapper {
            display: flex;
            margin-bottom: 30px;
        }
    }
}
