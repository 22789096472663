.adminPanelUser {
    &Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        border: 1px solid #efeef1;
        border-radius: 10px;
        padding: 10px;
        gap: 15px;
    }
    &Name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        img {
            height: 35px;
            border-radius: 50%;
            display: block;
        }
    }
    &Status {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        align-self: flex-end;
        height: 100%;
        &Input {
            width: 105px;
            height: 40px;
            border-radius: 10px;
        }
        &LoaderWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 105px;
        }
    }
}
