.repoMainOwnPlaybook {
    &Logo {
        display: flex;
        justify-content: center;
    }
    &Container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 60px;
        @media (max-width: 425px) {
            gap:30px;
        }
    }
    &Main {
        &Wrapper {
            padding: 80px 200px 120px 200px;
            display: flex;
            gap: 15px;
            flex-direction: column;
            align-items: center;
            @media (max-width: 768px) {
                padding: 80px 40px 40px 40px;
                display: flex;
            gap: 15px;
            flex-direction: column;
            align-items: center;
            }
        }
        &Title {
            display: flex;
            justify-content: center;
            width: 100%;
            color: #262626;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 140%;
            text-transform: capitalize;
            margin: 40px 0 0 0;
            @media (max-width: 768px) {
                font-size: 30px;
            }
        }
        &Button {
            display: flex;
            justify-content: center;
           // width: 100%;
        }
    }
    &Link {
        text-decoration: none;
    }
    &AddNewButton {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        width: 120px;
        height: 38px;
        border-radius: 38px;
        background-color: #096BBB;
        text-transform: none;
        color: #fff;
        text-decoration: none;

        &:hover {
            background-color: #2679BC;
        }
        @media (max-width: 374px) {
            gap: 0px;
            font-size: 12px;
            width: 105px;
        }
    }
    &Text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &Title {
            margin: 0;
            color: #262626;
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 140%;
            @media (max-width: 425px) {
                font-size: 30px;
            }
            &Blue {
                color: #096bbb;
            }
        }
        &Description {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 180%;
            @media (max-width: 425px) {
                text-align: center;
            }
        }
    }
}
