.calendlyModal {
    &Wrapper {
        width: 70vw;
        height: 80vh;
        margin: 10vh auto;
        background-color: white;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        iframe {
            width: 100%;
            height: 100%;
            margin: 10px;
            border: none;
            border-radius: 10px;
        }
    }
    &Loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
