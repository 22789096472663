.optionsPopOver {
    &Wrapper {
        position: absolute;
        padding: 8px;
        width: 180px;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #efeef1;
        box-shadow: 0px 4px 20px 0px rgba(30, 41, 59, 0.1);
        backdrop-filter: blur(40px);
    }
    &Items {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 4px;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: #efeef1;
        }
        &Wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}
