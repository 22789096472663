.repoElement {
    &Dialog {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        &Contaier {
            width: 90%;
            height: 90%;
            max-width: 100%;
            margin: 0;
            border-radius: 24px;
            overflow-y: auto;
        }
        &Title {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #efeef1;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            &Logo {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: 10px;
                align-items: center;
                gap: 10px;
                span {
                    display: flex;
                    align-items: center;
                }
                @media (max-width: 375px) {
                    width: 100%;
                    font-size: 10px;
                    gap: 3px;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            &Actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
            }
            &Demo {
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding: 8px 16px;
                border-radius: 38px;
                border: 1px solid #262626;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                height: 38px;
                color: #000;
                text-transform: none;
                &:hover {
                    opacity: 0.7;
                }
            }
            @media (max-width: 320px) {
                font-size: 14px;
                font-weight: 700;
                padding: 16px 10px;
            }
            @media (max-width: 375px) {
                gap: 10px;
            }
            &Icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                font-size: 9px;
                font-weight: 400;
                &Try {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    width: 90px;
                    height: 38px;
                    border-radius: 38px;
                    background-color: #5e4afd;
                    text-transform: none;
                    color: #fff;
                    &:hover {
                        background-color: #796aea;
                    }
                    @media (max-width: 374px) {
                        gap: 0px;
                        font-size: 12px;
                        width: 85px;
                    }
                }
                &AddToMyRepo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    width: 160px;
                    height: 38px;
                    border-radius: 38px;
                    background-color: #5e4afd;
                    text-transform: none;
                    color: #fff;
                    &:hover {
                        background-color: #796aea;
                    }
                    @media (max-width: 374px) {
                        gap: 0px;
                        font-size: 12px;
                        width: 85px;
                    }
                }

                &Link {
                    display: flex;
                    justify-content: center;
                    img {
                        margin-top: 2px;
                    }
                }
                span {
                    @media (max-width: 375px) {
                        width: 50px;
                    }
                }
            }
            &Close {
                &Icon {
                    cursor: pointer;
                    height: 24px;
                    width: 24px;
                }
            }
        }
        &Content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
        }
    }
}

.link {
    text-decoration: none;
    color: inherit;
}
